export const tokenLayers = {
  'Bitcoin': {
    src: 'https://assets.coingecko.com/coins/images/1/standard/bitcoin.png?1696501400'
  },
  'Ethereum': {
    src: 'https://assets.coingecko.com/coins/images/279/standard/ethereum.png?1696501628'
  },
  'Solana': {
    src: 'https://assets.coingecko.com/coins/images/4128/standard/solana.png?1718769756'
  },
  'Aptos': {
    src: 'https://assets.coingecko.com/coins/images/26455/standard/aptos_round.png?1696525528',
  },
  'Arbitrum': {
    src: 'https://assets.coingecko.com/coins/images/16547/standard/arb.jpg?1721358242',
  },
  'Avalanche': {
    src: 'https://assets.coingecko.com/coins/images/12559/standard/Avalanche_Circle_RedWhite_Trans.png?1696512369',
  },
  'Injective': {
    src: 'https://assets.coingecko.com/coins/images/12882/standard/Secondary_Symbol.png?1696512670',
  },
  'Optimism': {
    src: 'https://assets.coingecko.com/coins/images/25244/standard/Optimism.png?1696524385',
  },
  'Polkadot': {
    src: 'https://assets.coingecko.com/coins/images/12171/standard/polkadot.png?1696512008',
  },
  'Sei': {
    src: 'https://assets.coingecko.com/coins/images/28205/standard/Sei_Logo_-_Transparent.png?1696527207',
  },
  'Sui': {
    src: 'https://assets.coingecko.com/coins/images/26375/standard/sui_asset.jpeg?1696525453',
  },
  'TRON': {
    src: 'https://assets.coingecko.com/coins/images/1094/standard/tron-logo.png?1696502193',
  },
  'Radix Ecosystem': {
    src: 'https://assets.coingecko.com/coins/images/4374/standard/6266da240f9ff5b6237a154c_Radix-Icon-256x256.png?1719376420'
  },
  'Other': {
    src: '/icons/project-placeholder-icon.svg',
  },
}
